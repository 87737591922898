<template>
  <div class="layout-padding row justify-center" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">Profile</div>
      </div>

      <div class="row q-mt-xl">
        <div class="col-4">
          <div class="text-h6 q-mt-sm text-right q-mr-lg">Profile Picture</div>
        </div>
        <q-card flat class="col-8 bg-lmsBackground">
          <q-card-section class="row full-width">
            <div class="row full-width">
              <div class="row items-center cursor-pointer" @click="showAvatarModal = true">
                <q-avatar size="100px">
                  <q-img :src="user.avatar || $consts.DEFAULT_AVATAR"  style="height: 100px; width: 100px; border-radius: 25px;"/>
                </q-avatar>
                <div>
                  <q-btn flat @click="showAvatarModal = true" class="q-ml-sm">Change</q-btn>
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div class="row q-mt-xl" v-if="enableUserTheme">
        <div class="col-4">
          <div class="text-h6 q-mt-sm text-right q-mr-lg">Update Theme</div>
        </div>
        <q-card flat class="col-8 bg-lmsBackground">
          <q-card-section class="row">
            <q-select filled map-options emit-value @input="updateUser" :options="$store.getters['themes/getThemes']" option-value="uid" option-label="label" label="Current Theme" class="q-mb-lg col-12" v-model="user.theme_uid" />
          </q-card-section>
        </q-card>
      </div>
      <div class="row q-mt-xl">
        <div class="col-4">
          <div class="text-h6 q-mt-sm text-right q-mr-lg">Change Password</div>
        </div>
        <q-card flat class="col-8 bg-lmsBackground">
          <q-form @submit="updatePassword" autocomplete="off">
            <q-card-section class="row full-width">
              <q-input filled label="Current Password" v-model.trim="password_current" ref="password_current" type="password" class="q-mb-lg col-12" text-color="primaryText" lazy-rules :rules="formRules.passwordCurrent" />
              <q-input filled label="New Password" v-model.trim="password" ref="password" type="password" class="q-mb-lg col-12" lazy-rules :rules="formRules.password" />
              <q-input filled label="Confirm New Password" v-model.trim="password_confirmation" ref="password_confirmation" class="col-12" type="password" lazy-rules :rules="formRules.passwordConfirm" />
            </q-card-section>
            <q-card-section>
              <q-btn label="Save Password" type="submit" color="primary" />
            </q-card-section>
          </q-form>
        </q-card>
      </div>

      <q-dialog v-model="showAvatarModal">
        <q-card style="width: 560px; max-height: 900px;">
          <div class="example-avatar q-pa-lg">
            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
              <h3>Drop files to upload</h3>
            </div>
            <div class="avatar-upload" v-show="!editAvatar">
              <div class="text-center p-2">
                <label for="avatar">
                  <q-img id="tempAvatar" :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" class="rounded-circle" style="height: 250px; width: 250px; border-radius: 125px;" />
                  <h4 class="pt-2">Drop files anywhere to upload</h4>
                </label>
              </div>
              <div class="text-center p-2">
                <file-upload extensions="gif,jpg,jpeg,png,webp" accept="image/png,image/gif,image/jpeg,image/webp" class="btn btn-primary text-italic q-ma-md"
                  :headers="{ 'Authorization': 'Bearer ' + this.$store.getters['auth/accessToken'] }" :post-action="postURL" :drop="!editAvatar" v-model="files"
                  @input-filter="inputFilter" @input-file="inputFile" ref="upload">or click here to upload avatar..</file-upload>
              </div>
              <div class="text-center">
                <q-btn flat @click.native.prevent="showAvatarModal = false">Close</q-btn>
              </div>
            </div>

            <div class="avatar-edit" v-show="files.length && editAvatar">
              <div class="avatar-edit-image" id="uploaderAddImageLinkCropperContainer" v-if="files.length">
                <img ref="editImage" :src="files[0].url" />
              </div>
              <div class="row gutter-xs q-my-lg">
                <q-btn flat type="button" class="margin-all-1 button-custom-color" @click.native.prevent="$refs.upload.clear">Cancel</q-btn>
                <q-btn type="submit" class="margin-all-1 button-custom-color" @click.native.prevent="editSave" color="primary">Save</q-btn>
              </div>
            </div>
          </div>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'

export default {
  name: 'ProfilePage',
  components: {
    FileUpload
  },
  data () {
    return {
      files: [],
      postURL: this.$consts.API_URL + this.$consts.UPDATE_AVATAR_URL,

      editAvatar: false,
      showAvatarModal: false,
      cropper: false,
      password: '',
      password_current: '',
      password_confirmation: '',

      user: {}
    }
  },
  methods: {
    inputFile (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.editAvatar = true
        })
      }
      if (!newFile && oldFile) {
        this.editAvatar = false
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png)$/i.test(newFile.name)) {
          alert('Your choice is not a picture')
          return prevent()
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    editSave () {
      this.oldFile = this.files[0]
      let blob = this.cropper.getCroppedCanvas()

      var uA = window.navigator.userAgent
      var isIE = /msie\s|trident\/|edge\/\d./i.test(uA)

      if (isIE) {
        this.updateBlob(blob.msToBlob())
      } else {
        blob.toBlob((blob) => {
          this.updateBlob(blob)
        }, this.files[0].type, 1)
      }
    },
    updateBlob (blob) {
      this.newBlob = blob
      let URL = window.URL || window.webkitURL
      let tURL = URL.createObjectURL(blob)
      this.oldFile = this.files[0]
      this.newFile = tURL
      this.files[0].url = tURL
      this.$refs.upload.update(this.files[0].id, {
        blob,
        size: blob.size,
        type: 'image/jpeg'
      })
      document.querySelector('#tempAvatar').src = this.newFile
      this.editAvatar = false
      this.saveAvatar()
    },
    async updateUser () {
      this.$q.loading.show()
      try {
        let res = await this.$store.dispatch('auth/updateUser', { user: this.user })
        if (res.code === 200) {
          this.$successMsg('Theme Updated')
          this.$setTheme(this.$store.getters['auth/userTheme'])
        }
        this.$q.loading.hide()
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg('Sorry, something went wrong')
        throw e
      }
    },
    async saveAvatar () {
      this.$q.loading.show()

      try {
        await this.$store.dispatch('auth/uploadAvatar', this.newBlob)
        this.$q.loading.hide()
        this.showAvatarModal = false
        this.$successMsg('Avatar updated')
      } catch (e) {
        this.$q.loading.hide()
        this.$failureMsg()
      }
    },
    async updatePassword () {
      this.$q.loading.show()

      let passwords = {
        password: this.password,
        password_confirmation: this.password_confirmation,
        current_password: this.password_current
      }

      try {
        let res = await this.$store.dispatch('auth/updatePassword', { uid: this.user.uid, passwords: passwords })

        if (res.code === 200) {
          this.$q.loading.hide()
          this.password = ''
          this.password_confirmation = ''
          this.password_current = ''
          this.$refs.password.resetValidation()
          this.$refs.password_confirmation.resetValidation()
          this.$refs.password_current.resetValidation()
          this.$successMsg('Password was updated successfully')
        }
      } catch (e) {
        this.$q.loading.hide()
        if (e.data.code === 406) {
          this.$failureMsg(e.data.message)
        }
      }
    }
  },
  watch: {
    editAvatar (value) {
      if (value) {
        if (!this.$refs.editImage) {
          return
        }
        document.querySelector('#uploaderAddImageLinkCropperContainer').style.height = (window.outerHeight * 0.4) + 'px'

        if (this.cropper) {
          this.cropper.destroy()
        }

        this.cropper = new Cropper(this.$refs.editImage, {
          aspectRatio: 1 / 1,
          viewMode: 2,
          responsive: true,
          autoCropArea: 1,
          dragMode: 'move'
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  computed: {
    formRules: function () {
      return {
        firstName: [ this.$formRules.required('First Name') ],
        lastName: [ this.$formRules.required('Last Name') ],
        passwordCurrent: [ this.$formRules.required('Current Password') ],
        password: [ this.$formRules.required('Password'), this.$formRules.minimum(5), this.$formRules.maximum(10) ],
        passwordConfirm: [ this.$formRules.required('Confirm Password'), this.$formRules.confirmPassword(this.password) ]
      }
    },
    enableUserTheme: {
      get () {
        return this.$store.state.auth.user.company.settings.enableUserTheme
      }
    }
  },
  async beforeCreate () {
    let cropperCSS = document.createElement('link')
    cropperCSS.setAttribute('href', 'https://cdn.jsdelivr.net/npm/cropperjs/dist/cropper.css')
    cropperCSS.setAttribute('rel', 'stylesheet')
    document.head.appendChild(cropperCSS)
  },
  created () {
    this.user = this.$store.getters['auth/user']
    this.user.theme_uid = this.$store.getters['auth/userThemeUid']
  }
}
</script>

<style lang="stylus" scoped>
#avatarModal img
  max-height 50%
  max-width 100%

.container-width
  width 800px
.img-custom-size
  height 6.5rem
  width 6.5rem
.loader
  position absolute
  top:30%
  left:45%
#department
  padding 8px
</style>

<style lang="stylus">
.modal-dark
  .modal
    .modal-content
      color white
      background #1b1a1a !important
    .modal-body
    input
      color white
      .q-if
        border-bottom 1px solid white
    button
      color #FF7B39 !important
      background #323232 !important
.example-avatar .avatar-upload .rounded-circle
  margin-top 30px
  width 200px
  height 200px

.example-avatar .text-center .btn
  margin 0 .5rem

.example-avatar .drop-active
  top 0
  bottom 0
  right 0
  left 0
  position fixed
  z-index 9999
  opacity .6
  text-align center
  background #000

.example-avatar .drop-active h3
  margin -.5em 0 0
  position absolute
  top 50%
  left 0
  right 0
  -webkit-transform translateY(-50%)
  -ms-transform translateY(-50%)
  transform translateY(-50%)
  font-size 40px
  color #fff
  padding 0

.imageContent
  width 50%
  max-height:100%

.cropper-container
  width 100% !important

.avatar-upload-white
  background-color #1b1a1a
  color white

.avatar-edit-dark
  background-color #1b1a1a !important
</style>
